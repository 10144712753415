import CmsIcon from '@components/Cms/CmsComponents/CmsIcon'
import { MICRO_TEMPLATE_ID } from '@components/TrustPilot/constants'
import { Carousel } from '@components/common/components/Carousel'
import config from '@configs/index'
import { IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import { isLXTeaser } from '@typesApp/teaser'
import React from 'react'
import {
  StyledKeySellingBarContentLink,
  KeySellingBarTrustPilotWrapper,
  KeySellingBarWrapper,
  KeySellingBarWrapperDesktop,
  KeySellingBarWrapperMobile,
} from './KeySellingBar.style'
import { Typography } from '@mui/material'
import Container from '@components/UI/Container'
import { getToLink } from '@components/common/helpers/cms'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { locationOriginSelector } from '@redux/selectors/site'

const TrustpilotWidgetMicro = dynamic(() => import('@components/TrustPilot/TrustpilotWidgetMicro'), {
  ssr: false,
})

interface IKeySellingBar {
  items: IPlacementItem[]
}

interface IKeySellingBarElement {
  icon?: string
  title: string
  cta: string
  dataname?: string
}

const PLAYWRITEHANDLE = 'keySellingBar'

const KeySellingBarElement: React.FC<IKeySellingBarElement> = ({ icon, title, cta, dataname }) => {
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  let iconElement: React.ReactNode = null
  if (!!icon && icon.length > 0) {
    iconElement = <CmsIcon teaserIcon={icon} />
  }

  return (
    <div data-element-id={'MainNav_Strip'}>
      <StyledKeySellingBarContentLink
        href={cta}
        data-name={dataname}
        locale={locale as string}
        isLocaleDomain={isLocaleDomain}
        locationOrigin={locationOrigin}
      >
        {iconElement}
        <Typography variant="subtitle2">{title}</Typography>
      </StyledKeySellingBarContentLink>
    </div>
  )
}

const KeySellingBar: React.FC<IKeySellingBar> = ({ items }) => {
  if (items.length === 0) return <></>
  const lxTeaserItems = items.filter(isLXTeaser)
  const hasTrustPilot = items.filter(item => item.viewtype === 'trust-pilot') ?? []

  const barElements = lxTeaserItems.map((item, index) => {
    return (
      <KeySellingBarElement
        key={index}
        icon={item.teaserIcon}
        title={item.teaserTitle1}
        cta={getToLink(item) || ''}
        dataname={PLAYWRITEHANDLE + `${Number(index) + 1}`}
      />
    )
  })
  const content = [...barElements]
  const contentMobile = [...barElements]

  if (config?.trustPilotEnabled && hasTrustPilot.length > 0) {
    content.push(
      <KeySellingBarTrustPilotWrapper key="trust-pilot">
        <TrustpilotWidgetMicro desktop widgetTemplateId={MICRO_TEMPLATE_ID} />
      </KeySellingBarTrustPilotWrapper>
    )

    contentMobile.push(
      <KeySellingBarTrustPilotWrapper key="trust-pilot">
        <TrustpilotWidgetMicro mobile widgetTemplateId={MICRO_TEMPLATE_ID} />
      </KeySellingBarTrustPilotWrapper>
    )
  }

  return (
    <KeySellingBarWrapper data-element-id="MainNav_Info">
      <Container>
        <KeySellingBarWrapperMobile>
          <Carousel autoplay autoplayInterval={5000} loop={true} slidesPerView={1} spaceBetween={60} swiping={false}>
            {contentMobile}
          </Carousel>
        </KeySellingBarWrapperMobile>
        <KeySellingBarWrapperDesktop>{content}</KeySellingBarWrapperDesktop>
      </Container>
    </KeySellingBarWrapper>
  )
}

export default KeySellingBar
